import React, { Dispatch, DragEvent, useCallback, useEffect, useRef, useState } from "react";

import { useTheme } from "@mui/material";
import ReactFlow, {
    addEdge,
    Background,
    BackgroundVariant,
    Connection,
    Controls,
    Edge,
    Node,
    ReactFlowProvider,
} from "reactflow";
import shortUUID from "short-uuid";

import { OperatorDeclaration } from "../interfaces/OperatorDeclaration";
import { tokens } from "../theme";
import { FnRun } from "../types/FnRun";
import { Fn } from "../types/Fn";
import { DagStepType } from "../types/FnDef";
import { nodesToDag } from "./OperatorNode";
import EdgeDataPopup from "./EdgeDataPopup";
import OperatorNode from "./OperatorNode";
import DagDiagramOperatorSelector from "./DagDiagramOperatorSelector";

import "reactflow/dist/style.css";

const nodeTypes = {
    operator: OperatorNode,
};

const getId = () => {
    const translator = shortUUID();
    return translator.new();
};

interface DagDiagramProps {
    nodes: any[];
    setNodes: Dispatch<React.SetStateAction<any[]>>;
    onNodesChange: (newState: any[]) => void;
    edges: any[];
    setEdges: Dispatch<React.SetStateAction<any[]>>;
    onEdgesChange: (newState: any[]) => void;

    operatorDeclarations: OperatorDeclaration[];

    fn: Fn | null;
    setFn: Dispatch<React.SetStateAction<Fn | null>>;

    fnRun: FnRun | null;
}

const DagDiagram: React.FC<DagDiagramProps> = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

    const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);

    useEffect(() => {
        if (props.fn && props.fn.fn_def.dag) {
            const { nodes, edges } = dagToNodes(props.fn.fn_def.dag, props.operatorDeclarations);
            props.setNodes(nodes);
            props.setEdges(edges);
        }
    }, [props.fn, props.operatorDeclarations]);

    const onEdgeClick = useCallback((event: React.MouseEvent, edge: Edge) => {
        setSelectedEdge(edge);
    }, []);

    const onPaneClick = useCallback(() => {
        setSelectedEdge(null);
    }, []);

    const updateDag = (newDag: DagStepType[]) => {
        if (props.fn) {
            const newFnDef = {
                ...props.fn.fn_def,
                dag: newDag,
            };
            props.fn.update(props.setFn, { fn_def: newFnDef });
        }
    };

    const onConnect = useCallback(
        (connection: Connection) => {
            const edge_id = `e-${connection.source}-${connection.target}-${connection.targetHandle}`;

            const edge: Edge = {
                id: edge_id,
                source: connection.source ?? "",
                target: connection.target ?? "",
                sourceHandle: connection.sourceHandle ?? "",
                targetHandle: connection.targetHandle ?? "",
            };
            props.setEdges((eds) => {
                const newEdges = addEdge(edge, eds);
                const newDag = nodesToDag(props.nodes, newEdges);
                updateDag(newDag);
                return newEdges;
            });
        },
        [props.nodes, props.setEdges, props.edges, props.fn, props.setFn],
    );

    const onEdgesDelete = useCallback(
        (edgesToDelete: Edge[]) => {
            props.setEdges((currentEdges) => {
                const edgeIdsToDelete = edgesToDelete.map((edge) => edge.id);
                const newEdges = currentEdges.filter((edge) => !edgeIdsToDelete.includes(edge.id));
                const newDag = nodesToDag(props.nodes, newEdges);
                updateDag(newDag);
                return newEdges;
            });
        },
        [props.nodes, props.edges, props.setEdges, props.fn, props.setFn],
    );

    const onDragOver = useCallback((event: DragEvent) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    function dagToNodes(
        dag: DagStepType[],
        operatorDeclarations: OperatorDeclaration[],
    ): { nodes: Node[]; edges: Edge[] } {
        function findPlaceholder(operatorDeclaration: any, paramName: string): string {
            for (const parameter of operatorDeclaration.parameters) {
                if (parameter.name === paramName) {
                    return parameter.placeholder || ""; // return an empty string if placeholder is null
                }
            }
            return "";
        }

        const nodes: Node[] = [];
        const edges: Edge[] = [];

        const nodeMap = new Map<string, Node>();

        dag.forEach((dagNode, index) => {
            // Example value of dagNode here:
            // {
            //     "id":"dndnode_3",
            //     "position": {"x":-172.7086717860799,"y":-447.56448254136876},
            //     "width": 100,
            //     "height": 100,
            //     "operator":"Web search",
            //     "parameters":{"query":"test","results_count":null}
            // }

            const operatorDeclaration = operatorDeclarations.find(
                (op) => op.name === dagNode.operator,
            );
            // Example value of operatorDeclaration
            /*    {
                    "description": "",
                    "inputs": [],
                    "name": "Web search",
                    "outputs": [
                        {
                            "data_type": "{name,content}[]",
                            "name": "search_results"
                        }
                    ],
                    "parameters": [
                        {
                            "data_type": "string",
                            "name": "query",
                            "placeholder": "Enter your search query"
                        },
                        {
                            "data_type": "integer",
                            "name": "results_count",
                            "placeholder": "Enter the number of results"
                        }
                    ],
                    "additional_parameters": [
                        {
                            "data_type": "enum(gpt-4,gpt-3.5-turbo)",
                            "name": "model_preference",
                            "placeholder": ""
                        }
                    ],
                    "secrets": []
                }
            */

            if (!operatorDeclaration) {
                console.error(`Operator declaration not found for operator: ${dagNode.operator}`);
                return;
            }

            const node: Node = {
                id: dagNode.id,
                type: "operator",
                position: dagNode.position,
                selected: dagNode.selected,
                style: {
                    width: dagNode.width ?? undefined,
                    height: dagNode.height ?? undefined,
                },
                data: {
                    name: dagNode.operator,
                    parameters: Object.entries(dagNode.parameters).map(([name, value]) => ({
                        name,
                        placeholder: findPlaceholder(operatorDeclaration, name),
                        data_type: "string",
                        value,
                    })),
                    inputs: operatorDeclaration.inputs.map(({ name, data_type }) => ({
                        name,
                        data_type,
                    })),
                    outputs: operatorDeclaration.outputs.map(({ name, data_type }) => ({
                        name,
                        data_type,
                    })),
                    declaration: operatorDeclaration,
                    batch: dagNode.batch || false,

                    edges: props.edges,
                    setEdges: props.setEdges,
                    nodes: props.nodes,
                    setNodes: props.setNodes,
                    fn: props.fn,
                    setFn: props.setFn,
                },
            };

            nodes.push(node);
            nodeMap.set(node.id, node);

            if (dagNode.inputs) {
                Object.entries(dagNode.inputs).forEach(([targetHandle, [source, sourceHandle]]) => {
                    const edge_id = `e-${source}-${dagNode.id}-${targetHandle}`;

                    const edge: Edge = {
                        id: edge_id,
                        source,
                        target: dagNode.id,
                        sourceHandle,
                        targetHandle,
                        animated: true,
                        style: { strokeWidth: 4 },
                    };
                    edges.push(edge);
                });
            }
        });

        return { nodes, edges };
    }

    const onDrop = useCallback(
        (event: DragEvent) => {
            event.preventDefault();
            if (reactFlowInstance) {
                const reactFlowBounds = reactFlowWrapper.current!.getBoundingClientRect();
                const type = event.dataTransfer.getData("application/reactflow");
                const nodeData = JSON.parse(
                    event.dataTransfer.getData("application/reactflow-data"),
                );

                const position = reactFlowInstance.project({
                    x: event.clientX - reactFlowBounds.left,
                    y: event.clientY - reactFlowBounds.top,
                });

                const operatorDeclaration = props.operatorDeclarations.find(
                    (op) => op.name === nodeData.name,
                );
                const newNode: Node = {
                    id: getId(),
                    type,
                    position,
                    data: {
                        ...nodeData,
                        declaration: operatorDeclaration,

                        edges: props.edges,
                        setEdges: props.setEdges,
                        nodes: props.nodes,
                        setNodes: props.setNodes,
                        fn: props.fn,
                        setFn: props.setFn,
                    },
                };

                props.setNodes((nds: Node[]) => {
                    const updatedNodes = nds.concat(newNode);
                    const newDag = nodesToDag(updatedNodes, props.edges);
                    updateDag(newDag);
                    return updatedNodes;
                });
            }
        },
        [
            reactFlowInstance,
            props.edges,
            props.setEdges,
            props.nodes,
            props.setNodes,
            props.fn,
            props.setFn,
            props.operatorDeclarations,
        ],
    );

    const onNodeDragStop = useCallback(
        (event: any, node: Node) => {
            props.setNodes((ns) => {
                const updatedNodes = ns.map((n) => (n.id === node.id ? node : n));
                const newDag = nodesToDag(updatedNodes, props.edges);
                updateDag(newDag);
                return updatedNodes;
            });
        },
        [props.setNodes, props.edges, props.setEdges, props.fn, props.setFn],
    );

    const onEdgeUpdate = useCallback(
        (oldEdge: Edge, newConnection: Connection) => {
            const newEdge: Edge = {
                ...oldEdge,
                source: newConnection.source ?? "",
                target: newConnection.target ?? "",
                sourceHandle: newConnection.sourceHandle || null,
                targetHandle: newConnection.targetHandle || null,
                //style: { strokeWidth: 3 }
            };

            props.setEdges((es) => {
                const updatedEdges = es.map((e) => (e.id === oldEdge.id ? newEdge : e));
                const newDag = nodesToDag(props.nodes, updatedEdges);
                updateDag(newDag);
                return updatedEdges;
            });
        },
        [props.nodes, props.fn, props.setFn],
    );

    const renderEdgeDataPopup = () => {
        if (selectedEdge && props.fnRun && props.fnRun.step_outputs) {
            return <EdgeDataPopup edge={selectedEdge} stepOutputs={props.fnRun.step_outputs} />;
        }
        return null;
    };

    return (
        <div className="flex h-full flex-col">
            <ReactFlowProvider>
                <div className="flex min-w-full flex-grow flex-row-reverse">
                    <div
                        className="h-full flex-grow"
                        ref={reactFlowWrapper}
                        style={{ height: "100%" }}>
                        <ReactFlow
                            nodes={props.nodes}
                            edges={props.edges}
                            onNodesChange={props.onNodesChange}
                            onEdgesChange={props.onEdgesChange}
                            onConnect={onConnect}
                            onInit={setReactFlowInstance}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            fitView
                            nodeTypes={nodeTypes}
                            onNodeDragStop={onNodeDragStop}
                            onEdgeUpdate={onEdgeUpdate}
                            onEdgesDelete={onEdgesDelete}
                            onEdgeClick={onEdgeClick}
                            onPaneClick={onPaneClick}
                            minZoom={0.1}>
                            <Controls />
                            {renderEdgeDataPopup()}
                            <Background color="#ccc" variant={BackgroundVariant.Dots} />
                        </ReactFlow>
                    </div>
                    <DagDiagramOperatorSelector ops={props.operatorDeclarations} />
                </div>
            </ReactFlowProvider>
        </div>
    );
};

export default DagDiagram;
