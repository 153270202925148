import React, { useEffect, useState, Dispatch, SetStateAction } from "react";

import {
    Card,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useTheme,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Cookies from "js-cookie";

import { tokens } from "../theme";
import { Fn } from "../types/Fn";
// Assuming Space class is available here
import { UserProfile } from "../types/UserProfile";
import { Space } from "../types/Space";

interface RunSettingsProps {
    userProfile: UserProfile;
    fn: Fn | null;
    setFn: Dispatch<SetStateAction<Fn | null>>;
    spaceId: string;
    setSpaceId: Dispatch<SetStateAction<string>>;
}

function RunSettings(props: RunSettingsProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [spaces, setSpaces] = useState<Space[]>([]);

    useEffect(() => {
        const fetchSpaces = async () => {
            try {
                const newSpaces = await Space.get_spaces_for_user(props.userProfile.id_token || "");
                setSpaces(newSpaces);
                const storedSpaceId = Cookies.get("selected_space_id") || "";
                if (newSpaces.find((space) => space.space_id === storedSpaceId)) {
                    props.setSpaceId(storedSpaceId);
                }
            } catch (error) {
                console.error("Error fetching spaces:", error);
            }
        };

        if (props.userProfile.id_token) {
            fetchSpaces();
        }
    }, [props.userProfile.id_token]);

    const handleModelChange = (event: SelectChangeEvent<string>) => {
        if (props.fn) {
            const newFnDef = {
                ...props.fn.fn_def,
                preferred_model: event.target.value as string,
            };
            props.fn.update(props.setFn, {
                fn_def: newFnDef,
            });
        }
    };

    const handleTemperatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.fn) {
            const newFnDef = {
                ...props.fn.fn_def,
                temperature: parseFloat(event.target.value),
            };
            props.fn.update(props.setFn, {
                fn_def: newFnDef,
            });
        }
    };

    const handleSpaceChange = (event: SelectChangeEvent<string>) => {
        const selectedSpaceId = event.target.value as string;
        props.setSpaceId(selectedSpaceId);
        Cookies.set("selected_space_id", selectedSpaceId, { expires: 7 });
    };

    return (
        <Card
            className="flex flex-col space-y-5 rounded-sm border p-4"
            sx={{ borderColor: colors.primary[300], backgroundColor: colors.primary[400] }}>
            <FormControl>
                <InputLabel id="model-select-label">Select preferred Model</InputLabel>
                <Select
                    labelId="model-select-label"
                    label="Select Model"
                    value={props.fn?.fn_def?.preferred_model ?? ""}
                    onChange={handleModelChange}
                    name="model-selector">
                    <MenuItem value={"gpt-4o"}>GPT-4o</MenuItem>
                    <MenuItem value={"gpt-4-turbo"}>GPT-4 Turbo</MenuItem>
                    <MenuItem value={"gpt-4"}>GPT-4</MenuItem>
                    <MenuItem value={"gpt-3.5-turbo"}>GPT-3.5-Turbo</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="space-select-label">Select Space</InputLabel>
                <Select
                    labelId="space-select-label"
                    label="Select Space"
                    value={props.spaceId}
                    onChange={handleSpaceChange}
                    name="space-selector">
                    <MenuItem value={"none"}>None (Run Pipeline in standalone mode)</MenuItem>
                    {spaces.map((space) => (
                        <MenuItem key={space.space_id} value={space.space_id}>
                            {space.name} (space_id: {space.space_id})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="flex flex-row items-center justify-start">
                <TextField
                    className="min-w-3/4 flex-1"
                    label="Temperature"
                    type="number"
                    inputProps={{ min: 0, max: 2, step: 0.1 }}
                    value={props.fn?.fn_def?.temperature ?? ""}
                    onChange={handleTemperatureChange}
                    variant="outlined"
                    size="small"
                />
            </div>
        </Card>
    );
}

export default RunSettings;
