import React, { useEffect, useState, useRef } from "react";

import { TextField } from "@mui/material";
import { Button, useTheme } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useEdgesState, useNodesState } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import app from "../auth/firebase";
import ActionFeed from "../components/ActionFeed";
import { fetch_with_auth } from "../components/Util";
import { tokens } from "../theme";
import { Fn } from "../types/Fn";
import { FnRun } from "../types/FnRun";
import { DagStepType } from "../types/FnDef";
import { UserProfile } from "../types/UserProfile";
import AuthPopup from "../components/AuthPopup";
import DagDiagram from "../components/DagDiagram";
import RunSettings from "../components/RunSettings";
import { apiUrl, pipelineRequiresAuth, setCurrentUrlParam } from "../components/Util";
import { nodesToDag } from "../components/OperatorNode";
import FnDialog from "../components/FnDialog";

const FunctionEditorPage = () => {
    const [showFnDialog, setShowFnDialog] = useState(false);

    const [providers, setProviders] = useState<string[]>([]);
    const [authRequired, setAuthRequired] = useState(false);

    const [spaceId, setSpaceId] = useState<string>("none");
    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));

    const auth = getAuth(app);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [operatorDeclarations, setOperatorDeclarations] = useState([]);

    const [fn, setFn] = useState<Fn | null>(null);
    const [fnRun, setFnRun] = useState<FnRun | null>(null);

    const [isRunning, setIsRunning] = useState(false);
    const [isActionFeedVisible, setIsActionFeedVisible] = useState(false);
    const location = useLocation();

    const formRef = useRef<HTMLFormElement | null>(null);

    const updateDag = (newDag: DagStepType[]) => {
        if (fn) {
            const newFnDef = {
                ...fn.fn_def,
                dag: newDag,
            };
            fn.update(setFn, { fn_def: newFnDef });
        }
    };

    const loadFunction = async (fnId: string) => {
        try {
            if (fnId) {
                const newFn = await Fn.loadFn(fnId);
                console.log(`loadFunctions: newFn = ${JSON.stringify(newFn)}`);
                if (newFn) {
                    setFn(newFn);
                    console.log(`fn_def after setting newFn: ${JSON.stringify(newFn.fn_def)}`);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error(`Failed to fetch Function: ${String(error)}`);
        }
    };

    const loadRunAndFn = async (runId: string) => {
        try {
            const { fn: newFn, fnRun: newFnRun } = await FnRun.loadFnAndRun(runId);
            if (newFn) {
                setFn(newFn);
            }
            if (newFnRun) {
                setFnRun(newFnRun);
            }
        } catch (error) {
            console.log(error);
            toast.error(`Failed to fetch Function Run: ${String(error)}`);
        }
    };

    useEffect(() => {
        // auto-save fn here
        if (fn) {
            handleSaveFn(fn);
        }
    }, [fn]);

    useEffect(() => {
        if (userProfile.id_token) {
            fetchOperatorDeclarations(userProfile.id_token);
            const searchParams = new URLSearchParams(window.location.search);
            const fnIdFromParams = searchParams.get("fn_id");
            const runIdFromParams = searchParams.get("run_id");

            if (!fnIdFromParams && !runIdFromParams) {
                setShowFnDialog(true);
            } else if (fnIdFromParams && !runIdFromParams) {
                loadFunction(fnIdFromParams);
            } else if (runIdFromParams) {
                loadRunAndFn(runIdFromParams);
            }
        }
    }, [userProfile.id_token]);

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            await userProfile.init(setUserProfile, currentUser);
        });

        return () => unsubscribe();
    }, []);

    const handleSaveFn = async (fn: Fn) => {
        try {
            if (!fn.fn_id) {
                const newFnId = uuidv4();
                fn.fn_id = newFnId;
            }

            setCurrentUrlParam("fn_id", fn.fn_id);
            setFn(fn);
            setShowFnDialog(false);
            //const fnFromBackend =
            await fetch_with_auth("fn", userProfile.id_token || "", "POST", {
                fn_id: fn.fn_id,
                name: fn.name,
                description: fn.description,
                authz: fn.authz,
                fn_def: fn.fn_def,
            });
            //setFn(fnFromBackend);
        } catch (error) {
            console.error("Failed to save Function:", error);
            toast.error(`Failed to save Function: ${String(error)}`);
        }
    };

    const fetchOperatorDeclarations = async (id_token: string) => {
        try {
            const data = await fetch_with_auth("operator_declarations", id_token, "GET");
            setOperatorDeclarations(data["pipeline_components"]);
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };

    const toggleActionFeed = () => {
        setIsActionFeedVisible(!isActionFeedVisible);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === "Enter") {
                event.preventDefault();
                formRef.current?.requestSubmit();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | null = null;

        if (isRunning) {
            let requestCount = 0;
            let intervalTime = 2000;

            const fetchAndUpdate = async () => {
                try {
                    if (fnRun) {
                        const newFnRun = await FnRun.loadRun(fnRun.run_id);

                        if (newFnRun) {
                            setIsRunning(newFnRun.isRunning());
                            fnRun.update(setFnRun, newFnRun);
                        }
                    }
                } catch (error) {
                    console.log(`fetchAndUpdate failed: ${error}`);
                }

                // Increment the request count and adjust the interval time if necessary
                requestCount++;
                if (requestCount % 3 === 0) {
                    intervalTime += 500;
                }

                // Schedule the next update
                interval = setTimeout(fetchAndUpdate, intervalTime);
            };

            // Start the updates
            fetchAndUpdate();
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isRunning]);

    const startOrKillPipelineRun = async () => {
        try {
            if (!fn) {
                alert(`Fn not set`);
                return;
            }

            const notification: string = isRunning
                ? toast.loading("Killing your pipeline run...")
                : toast.loading("Starting your pipeline run...");

            if (!isRunning) {
                const newFnRun = await FnRun.startRun(fn, userProfile, spaceId);

                // Not using update method because we create new run so it should not retain
                // any fields whatsoever from previous run.
                if (newFnRun) {
                    setFnRun(newFnRun);

                    setCurrentUrlParam("run_id", newFnRun.run_id);

                    toast.dismiss();
                    toast.success(`Function Run started!`);

                    setIsRunning(true);
                } else {
                    toast.error("Failed to start Function run!");
                }
            } else {
                const response = await fetch(`${apiUrl}/try_kill_fn_run`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        user_id: userProfile.user_id,
                        run_id: fnRun ? fnRun.run_id : "",
                    }),
                });

                if (response.ok) {
                    toast.dismiss();
                    toast.success("Pipeline run queued for termination.");
                } else {
                    const responseData = await response.json();
                    toast.error(`Error: ${responseData.error}`);
                }

                // Note that we are not seeting isRunning state to false here because we want the call to get_run
                // to learn about termination of the run and show all relevant log to the user.
            }
        } catch (error) {
            console.log(error);
            toast.dismiss();
            toast.error(String(error));
        }
    };

    const onFormSubmit = async (event: React.FormEvent) => {
        if (!fn) {
            alert(`Fn object not set!`);
            return;
        }

        event.preventDefault();

        const newDag = nodesToDag(nodes, edges);
        updateDag(newDag);
        fn.fn_def.dag = newDag;

        const requiredProviders = pipelineRequiresAuth(
            fn.fn_def.dag,
            auth.currentUser?.providerData,
        );

        // If this pipeline requires any sort of account linking (twitter, github, etc.) Force the user to sign in.
        if (requiredProviders.length > 0) {
            if (!auth.currentUser) {
                toast.error("This pipeline requires you to sign in!");
                return;
            }

            setProviders(requiredProviders);
            setAuthRequired(true);
            return;
        }

        startOrKillPipelineRun();
    };

    return (
        <div className={`h-full w-full`}>
            {showFnDialog ? (
                <FnDialog
                    fn={fn || new Fn({})}
                    onSave={handleSaveFn}
                    setShowFnDialog={setShowFnDialog}
                />
            ) : (
                <></>
            )}
            <div className="z-10 flex h-full min-w-full flex-col space-y-2">
                <div className="h-full w-full">
                    <form
                        ref={formRef}
                        onSubmit={onFormSubmit}
                        className="relative flex h-full flex-col space-y-4">
                        <AuthPopup authProviders={providers} authRequired={authRequired} />

                        <DagDiagram
                            nodes={nodes}
                            setNodes={setNodes}
                            onNodesChange={onNodesChange}
                            edges={edges}
                            setEdges={setEdges}
                            onEdgesChange={onEdgesChange}
                            operatorDeclarations={operatorDeclarations}
                            fn={fn}
                            setFn={setFn}
                            fnRun={fnRun}
                        />

                        <div className="absolute right-0">
                            <div className="flex items-center space-x-4">
                                <TextField
                                    label="Function Name"
                                    value={fn?.name || ""}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                                <Button variant="contained" onClick={() => setShowFnDialog(true)}>
                                    Edit Function Details
                                </Button>
                            </div>
                            <RunSettings
                                userProfile={userProfile}
                                fn={fn}
                                setFn={setFn}
                                spaceId={spaceId}
                                setSpaceId={setSpaceId}
                            />
                            <Button
                                className="w-full"
                                variant="contained"
                                type="submit"
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    color: isRunning
                                        ? `${colors.redAccent[500]} !important`
                                        : `${colors.greenAccent[500]} !important`,
                                    background: isRunning
                                        ? `${colors.redAccent[900]} !important`
                                        : `${colors.greenAccent[900]} !important`,
                                    "&:hover": {
                                        backgroundColor: isRunning
                                            ? `${colors.redAccent[800]} !important`
                                            : `${colors.greenAccent[800]} !important`,
                                    },
                                }}>
                                {isRunning
                                    ? "Kill Running Pipeline"
                                    : "Run Pipeline (ctrl + enter)"}
                            </Button>
                        </div>
                    </form>
                </div>

                <div id="action-feed-button" className="absolute z-40">
                    <Button variant="contained" onClick={toggleActionFeed}>
                        Toggle Action Feed
                    </Button>
                </div>
            </div>

            {isActionFeedVisible && (
                <div
                    id="action-feed-container"
                    className="absolute right-0 top-0 h-full w-full bg-white p-4 pt-0 shadow-lg md:w-1/2"
                    style={{ zIndex: 30 }}>
                    <ActionFeed fnRun={fnRun} isRunning={isRunning} />
                </div>
            )}
        </div>
    );
};

export default FunctionEditorPage;
