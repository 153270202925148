import { XYPosition } from "reactflow";

type DagStepType = {
    id: string;
    operator: string;
    parameters: Record<string, any>;
    inputs: Record<string, [string, string]>;
    position: XYPosition;
    batch: boolean;
    width: number | null | undefined;
    height: number | null | undefined;
    selected: boolean | undefined;
};

export class FnDef {
    temperature: number;
    preferred_model: string;
    dag: DagStepType[];

    constructor(data: Partial<FnDef>) {
        this.temperature = data.temperature ?? 1;
        this.preferred_model = data.preferred_model ?? "gpt-4o";
        this.dag = data.dag ?? [];
    }
}

export type { DagStepType };
